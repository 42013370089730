<template>
  <step-lesson-layout :title="$t('module3.part3.lesson1.title')">
    <template v-slot:content>
      <!-- BLOCK 1 - prerequis -->
      <div class="lesson-block">
        <div class="title-wrapper">
          <p class="label">{{ $t('module3.part3.lesson1.block1.label') }}</p>
          <p class="title">{{ $t('module3.part3.lesson1.block1.title') }}</p>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.block1.content')"></p>
        <div class="check-list">
          <div class="check-group">
            <app-icon-block type="check" :content="$t('module3.part3.lesson1.block1.check1')"></app-icon-block>
          </div>
          <div class="check-group">
            <app-icon-block type="check" :content="$t('module3.part3.lesson1.block1.check2')"></app-icon-block>
            <app-icon-block type="warning" :content="$t('module3.part3.lesson1.block1.warning')"></app-icon-block>
          </div>
        </div>
      </div>

      <div class="global-vue">
        <div class="title-wrapper">
          <p class="title">{{ $t('module3.part3.lesson1.subtitle1') }}</p>
        </div>
        <div class="lesson-block">
          <div class="row">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape1.svg')" alt="step1">
              <div class="name-wrapper">
                <div class="step-number">1</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step1Name')"></div>
              </div>
            </div>
            <img class="arrow" :src="require('@/assets/module3/part3/arrow-etape.svg')" alt="arrow">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape2.svg')" alt="step2">
              <div class="name-wrapper">
                <div class="step-number">2</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step2Name')"></div>
              </div>
            </div>
            <img class="arrow" :src="require('@/assets/module3/part3/arrow-etape.svg')" alt="arrow">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape3.svg')" alt="step3">
              <div class="name-wrapper">
                <div class="step-number">3</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step3Name')"></div>
              </div>
            </div>
            <img class="arrow" :src="require('@/assets/module3/part3/arrow-etape.svg')" alt="arrow">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape4.svg')" alt="step4">
              <div class="name-wrapper">
                <div class="step-number">4</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step4Name')"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <img class="long-arrow" :src="require('@/assets/module3/part3/arrow-etape-long.svg')" alt="long arrow">
          </div>
          <div class="row">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape5.svg')" alt="step5">
              <div class="name-wrapper">
                <div class="step-number">5</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step5Name')"></div>
              </div>
            </div>
            <img class=arrow :src="require('@/assets/module3/part3/arrow-etape.svg')" alt="arrow">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape6.svg')" alt="step6">
              <div class="name-wrapper">
                <div class="step-number">6</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step6Name')"></div>
              </div>
            </div>
            <img class="arrow" :src="require('@/assets/module3/part3/arrow-etape.svg')" alt="arrow">
            <div class="step-wrapper">
              <img :src="require('@/assets/module3/part3/etape7.svg')" alt="step7">
              <div class="name-wrapper">
                <div class="step-number">7</div>
                <div v-html="$t('module3.part3.lesson1.vueGlobale.step7Name')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lesson-block">
        <div class="step-wrapper">
          <Transition name="fade" mode="out-in">
            <carousel-item
              :img-src="currentStep.img"
              :label="currentStep.label"
              :title="currentStep.title"
              :key="'carousel-item-' + stepIndex"
            >
              <template v-slot:default>
                <component :is="currentStepComponent"></component>
              </template>
            </carousel-item>
          </Transition>
        </div>

        <div class="pagination">
          <div class="prev-nav-button">
            <prev-button-layout @click="prevDevisStep" v-show="stepIndex > 0"></prev-button-layout>
          </div>
          <p class="page-index">
            {{ stepIndex + 1 }} / {{ steps.length }}
          </p>
          <div class="next-nav-button">
            <next-button-layout @click="nextDevisStep" v-show="!isLastStep"></next-button-layout>
          </div>
        </div>
      </div>

      <!-- RESUME BUTTON -->
      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">{{ $t('global.navigation.resume') }}</app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import AppIconBlock from '@/components/layout/AppIconBlock'
import Drawer from '@/components/Drawer'
import AppTabs from '@/components/AppTabs'
import PrevButtonLayout from '@/components/buttons/PrevButtonLayout.vue'
import NextButtonLayout from '@/components/buttons/NextButtonLayout.vue'
import useTrainingUtils from '@/utils/useTrainingUtils'
import CarouselItem from '@/components/training/module3/part3/CarouselItem.vue'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Module3Part3Lesson1',
  components: { CarouselItem, NextButtonLayout, PrevButtonLayout, AppTabs, Drawer, AppIconBlock, AppButtonLayout, StepLessonLayout },
  setup (props, context) {
    const { goToNextStep, resumeLater } = useTrainingUtils()
    return { goToNextStep, resumeLater }
  },
  data () {
    return {
      stepIndex: 0,
      steps: [
        {
          id: 0,
          img: require('@/assets/module3/part3/etape1.svg'),
          label: this.$t('module3.part3.lesson1.step1.label'),
          title: this.$t('module3.part3.lesson1.step1.title'),
          component: async () => await import('./carousel-items/CarouselItemCompteMpr.vue')
        },
        {
          id: 1,
          img: require('@/assets/module3/part3/etape2.svg'),
          label: this.$t('module3.part3.lesson1.step2.label'),
          title: this.$t('module3.part3.lesson1.step2.title'),
          component: async () => await import('./carousel-items/CarouselItemDepotDemande.vue')
        },
        {
          id: 2,
          img: require('@/assets/module3/part3/etape3.svg'),
          label: this.$t('module3.part3.lesson1.step3.label'),
          title: this.$t('module3.part3.lesson1.step3.title'),
          component: async () => await import('./carousel-items/CarouselItemAnah.vue')
        },
        {
          id: 3,
          img: require('@/assets/module3/part3/etape4.svg'),
          label: this.$t('module3.part3.lesson1.step4.label'),
          title: this.$t('module3.part3.lesson1.step4.title'),
          component: async () => await import('./carousel-items/CarouselItemTravaux.vue')
        },
        {
          id: 4,
          img: require('@/assets/module3/part3/etape5.svg'),
          label: this.$t('module3.part3.lesson1.step5.label'),
          title: this.$t('module3.part3.lesson1.step5.title'),
          component: async () => await import('./carousel-items/CarouselItemPaiement.vue')
        },
        {
          id: 5,
          img: require('@/assets/module3/part3/etape6.svg'),
          label: this.$t('module3.part3.lesson1.step6.label'),
          title: this.$t('module3.part3.lesson1.step6.title'),
          component: async () => await import('./carousel-items/CarouselItemControle.vue')
        },
        {
          id: 6,
          img: require('@/assets/module3/part3/etape7.svg'),
          label: this.$t('module3.part3.lesson1.step7.label'),
          title: this.$t('module3.part3.lesson1.step7.title'),
          component: async () => await import('./carousel-items/CarouselItemVersement.vue')
        }
      ]
    }
  },
  computed: {
    isLastStep () {
      return this.stepIndex >= this.steps.length - 1
    },
    currentStep () {
      return this.steps[this.stepIndex]
    },
    currentStepComponent () {
      return defineAsyncComponent(this.currentStep.component)
    }
  },
  methods: {
    nextDevisStep () {
      this.stepIndex++
    },
    prevDevisStep () {
      this.stepIndex--
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/module3Lesson';

:deep(.global-vue) {
  margin-bottom: $space-s;
  .title {
    color: $c-secondary;
    font-size: $fz-xl;
    font-weight: $fw-l;
    text-align: left;
    text-transform: uppercase;
    margin: $space-sm;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    .step-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 130px;
      .name-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .step-number {
          min-width: 30px;
          color: white;
          background-color: $c-secondary;
          font-weight: $fw-l;
          padding: $space-xs $space-s;
          border-radius: 50%;
          width: fit-content;
        }
      }
    }
    .long-arrow {
      margin-left: 6rem;
      width: 58%;
    }
  }
}

:deep(.lesson-block) {
  .title-wrapper {
    text-align: left;
    .label {
      font-size: $fz-xs;
      font-weight: $fw-l;
      text-transform: uppercase;
    }
    .title {
      font-size: $fz-xl;
      text-align: left;
    }
  }
  .app-text-drawer + .app-text-drawer {
    margin-top: $space-s;
  }
  .app-text-drawer + .app-icon-block {
    margin-top: $space-m;
  }
  .image-tabs-wrapper {
    .image-tab {
      height: 160px;
    }
    .tab-content {
      padding: $space-s;
      .tab-title {
        color: $c-secondary;
        font-family: $ff-big;
        font-size: $fz-xl;
        font-weight: $fw-l;
      }
      .paragraphe {
        margin-top: $space-s;
        color: $c-text-dark;
        font-family: $ff;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  :deep(.grid-wrapper) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-s;
    .app-text-drawer + .app-text-drawer {
      margin-top: 0;
    }
  }
}

@media (max-width: $bp-tablet) {
  :deep(.global-vue) {
    .row {
      .arrow, .long-arrow {
        display: none;
      }
      flex-direction: column;
      .step-wrapper {
        img {
          width: 18%;
        }
        padding-inline: $space-s;
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: $space-s;
        .name-wrapper {
          font-size: $fz-sm;
          flex-direction: row;
          gap: $space-s
        }
      }
    }
  }
}
</style>

<style lang="scss">
.step-tabs-wrapper {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
